const serverUrl="."; 

const  home  ={
  "meta": {
    "title": "Wim",
    "description": "Simplifica la gestión de pedidos en tu negocio con Wim. Más de 50,000 negocios confían en nuestra app para sus pedidos en mesa, pedidos vía WhatsApp, menús digitales y más.",
  },
  "sections": [
    {
      "title": "Gestiona tus pedidos sin complicaciones",
      "message": "Nuestra APP simplifica la gestión y toma de pedidos en tu negocio o vía WhatsApp.",
      "image": "https://res.cloudinary.com/wikimenu/image/upload/v1717752763/screenshots/es/home-main_tviw8o.gif",
      "buttonUrl": "https://play.google.com/store/apps/details?id=com.campino.wikimenu&hl=es&gl=US&referrer=utm_source%3Dweb%26utm_medium%3DwebEs%26utm_campaign%3DinstalHome",
      "buttonAction": "install"
    },
    {
      "type": "grid",
      "title": "¿Por qué más de 50.000 negocios ya han instalado WikiMenu?",
      "message": "Estamos en constante evolución, siempre escuchando lo que realmente necesitan nuestros clientes.",
      "list": [
        {
          "icon": "qr_code_scanner",
          "title": "Menú realmente digital",
          "description": "Desde tu teléfono puedes actualizar tu stock al instante."
        },
        {
          "icon": "shopping_basket",
          "title": "Pedidos por WhatsApp",
          "description": "Ahorra tiempo y minimiza errores en tus pedidos por WhatsApp."
        },
        {
          "icon": "print",
          "title": "Impresión automática de pedidos",
          "description": "Envía la nota del pedido directamente al punto de preparación."
        },
        {
          "icon": "groups",
          "title": "Aplicación multiusuario",
          "description": "Agrega a tu equipo vendedores, camareros, cocineros y repartidores."
        },
        {
          "icon": "view_kanban",
          "title": "Tablero de pedidos",
          "description": "Sigue en tiempo real el estado de pedidos en el local, takeaway y delivery."
        },
        {
          "icon": "savings",
          "title": "0% en comisiones",
          "description": "Sin burocracia, sin plataformas de delivery ni pasarelas de pago."
        }
      ]
    },
    {
      "title": "Un menú que es realmente digital",
      "message": "Puedes actualizar tu catálogo de ventas o menú digital al instante. Sacar productos de stock, editar y agregar ítems con un par de clics.",
      "image": "https://res.cloudinary.com/wikimenu/image/upload/v1717493354/screenshots/es/web-delivery-gif_nbx9a0.gif",
      "buttonUrl": "https://play.google.com/store/apps/details?id=com.campino.wikimenu&hl=es&gl=US&referrer=utm_source%3Dweb%26utm_medium%3DwebEs%26utm_campaign%3DinstalHome",
      "buttonAction": "install"
    },
    {
      "title": "Acelera tus pedidos por WhatsApp",
      "message": "Simplifica tus pedidos por WhatsApp y evita errores con tu propia web de ventas. Tus clientes no tendrán que instalar nada ni hacer complicados registros, tan fácil para ti y tus clientes como el mismo WhatsApp.",
      "image": "https://res.cloudinary.com/wikimenu/image/upload/v1717423373/screenshots/es/web-delivery-gif_zm7qh5.gif",
      "buttonUrl": "https://www.wiki-menu.app/whatsorders",
      "buttonAction": "Saber más"
    },
    {
      "title": "Controla en todo momento el estado de tus pedidos",
      "message": "No importa dónde te encuentres, con el tablero de pedidos de nuestra app podrás realizar el seguimiento y control en tiempo real de los pedidos, desde que están pendientes de preparación hasta que han sido entregados.",
      "image": "https://res.cloudinary.com/wikimenu/image/upload/v1717439864/screenshots/es/web-pngs-2023-es_1_yjoygj.png",
      "buttonUrl": "https://play.google.com/store/apps/details?id=com.campino.wikimenu&hl=es&gl=US&referrer=utm_source%3Dweb%26utm_medium%3DwebEs%26utm_campaign%3DinstalHome",
      "buttonAction": "install"
    },
    {
      "title": "Agrega múltiples usuarios a tu equipo de trabajo",
      "message": "Tus colaboradores tendrán la responsabilidad y la información según el rol que tú les asignes, por ejemplo: Punto de venta, Punto de preparación o Repartidor.",
      "image": "https://res.cloudinary.com/wikimenu/image/upload/v1718306249/screenshots/es/17_y6get6.png"
    },
    {
      "title": "Punto de venta",
      "message": "Tu equipo podrá vender en cualquier sitio: en la barra, en mesa, en la feria de la esquina.",
      "image": "https://res.cloudinary.com/wikimenu/image/upload/v1718306237/screenshots/es/12_svmryo.png"
    },
    {
      "title": "Gestiona tu flota de reparto",
      "message": "Con nuestra app de repartidor sigue el estado de las entregas y repartidores. Además, tu equipo siempre estará informado de las entregas pendientes.",
      "image": "https://res.cloudinary.com/wikimenu/image/upload/v1717500690/screenshots/es/web-pngs-2023-es_oobpa7.png",
      "buttonUrl": "https://play.google.com/store/apps/details?id=com.campino.wikimenu&hl=es&gl=US&referrer=utm_source%3Dweb%26utm_medium%3DwebEs%26utm_campaign%3DinstalHome",
      "buttonAction": "install"
    },
    {
      "image": "https://res.cloudinary.com/wikimenu/image/upload/v1690849902/screenshots/es/web-pngs-2023-es_yog4ws.png",
      "title": "Crecemos con nuestros clientes",
      "message": "Únete a la comunidad de emprendedores exitosos que han transformado sus negocios de delivery. Comprueba por ti mismo las opiniones de nuestros clientes.",
      "buttonUrl": "https://play.google.com/store/apps/details?id=com.campino.wikimenu&hl=es",
      "buttonAction": "Reviews"
    },
    {
      "image": "https://res.cloudinary.com/wikimenu/image/upload/v1717502502/screenshots/es/web-pngs-2023-es_vwargg.png",
      "title": "¿Aún tienes dudas? ¿No estás convencido?",
      "message": "Te atendemos directamente por WhatsApp y pregunta por tu código promocional para que pruebes nuestra app totalmente gratis y sin ningún compromiso.",
      "buttonUrl": "https://wa.me/34691626600?text=DEMOWEB",
      "buttonAction": "WhatsApp"
    }
  ],
  "contact": {
    "title": "Contacto",
    "message": "Contacta a través de WhatsApp o Instagram",
    "whatsapp": "34691292271",
    "youtube": "wikimenu",
    "instagram": "wikimenu"
  },
  "menu": [
    {
      "name": "home",
      "link": "wim.to"
    },
    {
      "name": "Pedidos por WhatsApp",
      "link": "https://wim.to/page/whatsapporders"
    },
    {
      "name": "Menú digital",
      "link": "https://wim.to/page/digitalmenu"
    }
  ]
}; 


/*
  Format menu
 {
      "name": "Funciones",
      "submenu": [
        { "name": "Menú digital", "link": "#f" },
        { "name": "Web de ventas vía WhatsApp", "link": "#f" },
        { "name": "Imprimir pedidos", "link": "#f" },
        { "name": "Tablero de pedidos", "link": "#f" },
        { "name": "Apps para el equipo", "link": "#f" }
      ]
    }
    */

const contentWhatsapp ={
  "meta": {
    "title": "Wim",
    "description": "Ahorra tiempo y minimiza errores en tus ventas por WhatsApp. Descubre cómo esta app puede optimizar la gestión de pedidos y maximizar el rendimiento de tus ventas."
  },
  "contact": {
    "title": "Ponte en contacto",
    "message": "Nos puedes encontrar también en",
    "youtube": "wikimenu",
    "whatsapp": "34691626600",
    "instagram": "wikimenu"
  },
  "sections": [
    {
      "image": "https://res.cloudinary.com/wikimenu/image/upload/v1690858324/screenshots/es/web-app-store_nng7vr.gif",
      "title": "Únete a la revolución de las ventas por WhatsApp",
      "message": "Descarga nuestra app y con unos pocos clics estarás listo para empezar a recibir pedidos por WhatsApp",
      "buttonUrl": "https://play.google.com/store/apps/details?id=com.campino.wikimenu&hl=es&gl=US&referrer=utm_source%3Dweb%26utm_medium%3DwebEs%26utm_campaign%3DwimInstall",
      "buttonAction": "install"
    },
    {
      "image": "https://res.cloudinary.com/wikimenu/image/upload/v1691003651/screenshots/es/pedido_jaobiq.png",
      "title": "Convierte tus repetitivos chats de WhatsApp en ventas rápidas y efectivas",
      "message": "Acelera la toma de pedidos y gestión de pedidos vía WhatsApp de manera eficiente y sin complicaciones.",
      "buttonUrl": "https://play.google.com/store/apps/details?id=com.campino.wikimenu&hl=es&gl=US&referrer=utm_source%3Dweb%26utm_medium%3DwebEs%26utm_campaign%3DwimInstall",
      "buttonAction": "install"
    },
    {
      "list": [
        {
          "icon": "counter_1",
          "title": "Envía tu link de ventas a tu cliente",
          "description": ""
        },
        {
          "icon": "counter_2",
          "title": "El cliente selecciona los productos y envía el pedido",
          "description": ""
        },
        {
          "icon": "counter_3",
          "title": "Un link de confirmación llegará a tu WhatsApp con los detalles",
          "description": ""
        },
        {
          "icon": "counter_4",
          "title": "Abriendo el link verás los detalles del pedido",
          "description": ""
        },
        {
          "icon": "counter_5",
          "title": "Realiza la gestión y seguimiento en tiempo real",
          "description": ""
        }
      ],
      "image": "https://res.cloudinary.com/wikimenu/image/upload/v1718660205/screenshots/es/chat_vfoqub.gif",
      "title": "¿Cómo funciona?",
      "buttonUrl": "https://www.wiki-menu.app/store/219?demoweb=true",
      "buttonAction": "Pedido de prueba"
    },
    {
      "image": "https://res.cloudinary.com/wikimenu/image/upload/v1718749506/screenshots/es/ordering_waan8i.gif",
      "title": "Simplifica la experiencia de compra y maximiza las ventas",
      "message": "Comparte tu enlace de ventas por WhatsApp o redes sociales. Tus clientes accederán a tu catálogo actualizado y podrán completar sus pedidos, incluyendo adicionales y complementos, de manera rápida, sin necesidad de registrarse ni instalar nada.",
      "buttonUrl": "https://www.wiki-menu.app/store/219?demoweb=true",
      "buttonAction": "Pedido de prueba"
    },

    {
      "list": [
        {
          "icon": "attach_money",
          "title": "Aumenta el ticket medio",
          "description": "Pedidos con más productos, adicionales y complementos."
        },
        {
          "icon": "upload_file",
          "title": "Actualización instantánea del catálogo",
          "description": "Evita errores por falta de stock y añade nuevos productos al instante."
        },
        {
          "icon": "timer",
          "title": "Ahorra tiempo y evita preguntas repetitivas",
          "description": "Tu web de ventas recopila la información que configures: dirección, nombre y método de pago."
        },
        {
          "icon": "print_connect",
          "title": "Imprime los pedidos",
          "description": "Envía los pedidos directamente desde WhatsApp a la impresora de notas."
        },
        {
          "icon": "groups",
          "title": "Optimiza el trabajo en equipo",
          "description": "Sistema multiusuario para tus puntos de venta, repartidores y puntos de preparación."
        }
      ],
      "type": "grid",
      "title": "¿Cómo te ayudamos a optimizar tus ventas por WhatsApp?",
      "buttonUrl": "https://play.google.com/store/apps/details?id=com.campino.wikimenu&hl=es&gl=US&referrer=utm_source%3Dweb%26utm_medium%3DwebEs%26utm_campaign%3DwimInstall",
      "buttonAction": "install"
    },
    {
      "image": "https://res.cloudinary.com/wikimenu/image/upload/v1690849902/screenshots/es/web-pngs-2023-es_yog4ws.png",
      "title": "Crecemos con nuestros clientes",
      "message": "Únete a la comunidad de emprendedores exitosos que han transformado sus negocios de delivery. Comprueba por ti mismo las opiniones de nuestros clientes.",
      "buttonUrl": "https://play.google.com/store/apps/details?id=com.campino.wikimenu&hl=es&gl=US&referrer=utm_source%3Dweb%26utm_medium%3DwebEs%26utm_campaign%3DwimInstall",
      "buttonAction": "Reviews"
    },
    {
      "image": "https://res.cloudinary.com/wikimenu/image/upload/v1691001362/screenshots/es/2_uae4bu.png",
      "title": "¿Aún tienes dudas? ¿No estás convencido?",
      "message": "Envíanos un WhatsApp y solicita tu código promocional para que pruebes nuestro plan DELIVERY totalmente gratis y sin ningún compromiso.",
      "buttonUrl": "https://wa.me/34691626600?text=DEMOWEB",
      "buttonAction": "WhatsApp"
    }
  ],
  "language": "es"
}

const contentDigitalMenu ={
  "meta": {
    "title": "Menú Digital Wim",
    "description": "Con esta app puedes crear un menú digital con código QR en 5 minutos. Además, puedes actualizar el contenido del menú al instante."
  },
  "contact": {
    "title": "Ponte en Contacto",
    "message": "Nos puedes encontrar también en",
    "youtube": "wikimenu",
    "whatsapp": "34691626600",
    "instagram": "wikimenu"
  },
  "language": "es",
  "sections": [
    {
      "image": "https://res.cloudinary.com/wikimenu/image/upload/v1717493354/screenshots/es/web-delivery-gif_nbx9a0.gif",
      "title": "Un menú realmente digital",
      "message": "Descarga nuestra app y en 5 minutos tendrás publicado tu menú digital. Podrás actualizar el contenido al instante y tantas veces como necesites.",
      "buttonUrl": "https://play.google.com/store/apps/details?id=com.campino.wikimenu&hl=es&gl=US&referrer=utm_source%3Dweb%26utm_medium%3DwebEs%26utm_campaign%3DwimInstall",
      "buttonAction": "install"
    },
    {
      "list": [
        {
          "image": "https://res.cloudinary.com/wikimenu/image/upload/v1719430924/screenshots/carousel/es/6_nhr3f6.png",
          "title": "Hamburguesas"
        },
        {
          "image": "https://res.cloudinary.com/wikimenu/image/upload/v1719433127/screenshots/carousel/es/5_la4qlw.png",
          "title": "Pizzas"
        },
        {
          "image": "https://res.cloudinary.com/wikimenu/image/upload/v1719433089/screenshots/carousel/es/4_ysbx4z.png",
          "title": "Empanadas"
        },
        {
          "image": "https://res.cloudinary.com/wikimenu/image/upload/v1719430921/screenshots/carousel/es/3_idbemr.png",
          "title": "Ceviche"
        },
        {
          "image": "https://res.cloudinary.com/wikimenu/image/upload/v1719430921/screenshots/carousel/es/2_mbwoyg.png",
          "title": "Cafetería"
        },
        {
          "image": "https://res.cloudinary.com/wikimenu/image/upload/v1719430920/screenshots/carousel/es/1_ccw8pz.png",
          "title": "Restaurante"
        },
        {
          "image": "https://res.cloudinary.com/wikimenu/image/upload/v1719432927/screenshots/carousel/es/screenshots-web-es_lwqv0f.png",
          "title": "Lonchería"
        }
      ],
      "type": "carousel-row",
      "title": "Menú Digital para Todo Tipo de Negocio",
      "message": "Ideal para tiendas y restaurantes de todos los segmentos, sean grandes o pequeños.",
      "buttonUrl": "https://www.wiki-menu.app/qr/219",
      "buttonAction": "Ver demo"
    },
    {
      "image": "https://res.cloudinary.com/wikimenu/image/upload/v1717493354/screenshots/es/web-delivery-gif_nbx9a0.gif",
      "title": "No importa que tengas un menú diferente cada día",
      "message": "Con un par de clics actualizarás tu menú evitando errores y ofreciendo a tus clientes información clara sobre el menú del día.",
      "buttonUrl": "https://play.google.com/store/apps/details?id=com.campino.wikimenu&hl=es&gl=US&referrer=utm_source%3Dweb%26utm_medium%3DwebEs%26utm_campaign%3DwimInstall",
      "buttonAction": "install"
    },
    {
      "list": [
        {
          "icon": "timer",
          "title": "Interfaz Intuitiva",
          "description": "Diseña tu menú con una app fácil de usar."
        },
        {
          "icon": "upload_file",
          "title": "Actualizaciones en Tiempo Real",
          "description": "Modifica tus platos y precios en cualquier momento y desde cualquier lugar."
        },
        {
          "icon": "edit",
          "title": "Personalización",
          "description": "Agrega imágenes, descripciones y categorías para que tu menú sea único."
        },
        {
          "icon": "qr_code_2",
          "title": "Código QR",
          "description": "Genera un código QR para que tus clientes puedan acceder al menú fácilmente."
        },
        {
          "icon": "devices",
          "title": "Para Todos los Dispositivos",
          "description": "Tus clientes no tendrán que instalar nada, el menú se visualiza en el navegador."
        }
      ],
      "type": "grid",
      "title": "¡Transforma tu Menú en una Experiencia Digital!",
      "buttonUrl": "https://play.google.com/store/apps/details?id=com.campino.wikimenu&hl=es&gl=US&referrer=utm_source%3Dweb%26utm_medium%3DwebEs%26utm_campaign%3DwimInstall",
      "buttonAction": "install"
    },
    {
      "list": [
        {
          "icon": "counter_1",
          "title": "Instala WIM desde la tienda de Google Play",
          "description": ""
        },
        {
          "icon": "counter_2",
          "title": "La app te indicará paso a paso cómo crear tu menú",
          "description": ""
        },
        {
          "icon": "counter_3",
          "title": "Imprime el código QR y colócalo en tu establecimiento.",
          "description": ""
        },
        {
          "icon": "counter_4",
          "title": "Realiza cambios en tu menú cuando lo necesites.",
          "description": ""
        }
      ],
      "image": "https://res.cloudinary.com/wikimenu/image/upload/v1691001363/screenshots/es/3_swkuaa.png",
      "title": "¿Cómo Crear mi Menú Digital y Código QR?",
      "buttonUrl": "https://play.google.com/store/apps/details?id=com.campino.wikimenu&hl=es&gl=US&referrer=utm_source%3Dweb%26utm_medium%3DwebEs%26utm_campaign%3DwimInstall",
      "buttonAction": "install"
    },
    {
      "image": "https://res.cloudinary.com/wikimenu/image/upload/v1690849902/screenshots/es/web-pngs-2023-es_yog4ws.png",
      "title": "Crecemos con Nuestros Clientes",
      "message": "Ofrece a tus clientes una experiencia de menú digital moderna y accesible desde cualquier dispositivo. Comprueba por ti mismo las opiniones de nuestros clientes.",
      "buttonUrl": "https://play.google.com/store/apps/details?id=com.campino.wikimenu&hl=es&gl=US&referrer=utm_source%3Dweb%26utm_medium%3DwebEs%26utm_campaign%3DwimInstall",
      "buttonAction": "Reviews"
    },
    {
      "image": "https://res.cloudinary.com/wikimenu/image/upload/v1691001362/screenshots/es/2_uae4bu.png",
      "title": "Te Ayudamos a Digitalizar tu Menú",
      "message": "Envíanos un WhatsApp y solicita la digitalización de tu menú, totalmente gratis y sin ningún compromiso.",
      "buttonUrl": "https://wa.me/34691626600?text=Ayuda%20con%20la%20digitalizacion%20de%20mi%20menu",
      "buttonAction": "WhatsApp"
    }
  ],
  "language": "es", 
  "menu": [
    {
      "name": "home",
      "link": "wim.to"
    },
    {
      "name": "Funciones",
      "submenu": [
        { "name": "Menú digital", "link": "https://wim.to/page/whatsapporders" },
        { "name": "Pedidos por WhatsApp", "link": "https://wim.to/page/whatsapporders" },
      ]
    }
  ]
}



const content = contentDigitalMenu; 

export default content 