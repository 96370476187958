import React from 'react'
import '../css/logo.css';
import { useImagePath } from '../hooks/paths';

export default function Loading() {
    return (
        <div className="logo">
            <img src={`${useImagePath()}/m-pw.svg`} className="ic-logo" alt="logo" />
        </div>
    )
}
