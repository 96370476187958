import React from 'react'
import '../css/style-app.css';
import ContainerRow from './ContainerRow';
import ContainerGrid from './ContainerGrid';
import NavBar from './NavBar';
import Footer from './Footer';
import Contact from './Contact';
import ContainerCarouselRow from './ContainerCarouselRow';


export default function LandingPage(props) {
   
  const {content} = props; 

  return (
    <div className="body">
       <NavBar menu = {content.menu}/>
       <main>
       {
       content.sections? content.sections.map((element,index)=>(
         
          element.type=='grid' ? <ContainerGrid key={index} section={element} position={index}/>
          : element.type=='carousel-row' ?  <ContainerCarouselRow key={index} section={element} position={index}/>
          : <ContainerRow key={index} section={element} position={index}/>
            
       ))
       : "NO SECTIONS"
      }
       </main>
    
      {content.contact && 
      <Contact content={content.contact}/>
      }
      <Footer/>
    </div>
  );
}
