import { React } from 'react'
import { useImagePath } from '../hooks/paths'
import style from "../css/NavBar.module.css";
import { useState, useEffect } from 'react'
import NavItemMenu from './NavItemMenu';


/* const submenu = [
    { name: "Admin", link: '#courier' },
    { name: "Punto de venta", link: '#courier' },
    { name: "Punto de preparacion", link: '#courier' },
    { name: "Repartidor", link: '#courier' }
]
const menu = [
    { name: "Featues", link: '#features', submenu:submenu},
    { name: "Apps y roles", submenu:submenu  },
    { name: "Precios", link: '#Precios' },
] */



export default function NavBar(props) {
    const menu = props.menu; 

    const urlImage = useImagePath() + "/m-pw.svg"
    const [openMenuMobile, setOpenMenuMobile] = useState(false)


    const handlerClickMenu = (open) => {
        console.log("handlerClickMenu", open)
        if (open) document.body.style.overflow = "hidden";
        else document.body.style.overflow =  "scroll"; 
        setOpenMenuMobile(open)
    }



    return (
        <header className={style.header}>
            <div className={style.brand}>
                <img src={urlImage} className={style.brandImage} />
                <p ><span className={style.brandTitle}>wim</span>
                    <span className={style.brandTitleMenu}></span>
                </p>
            </div>

            { menu && 
            <div className={style.displayButtonMenuMobile}>             
                {
                    openMenuMobile ?
                        <div className={style.menuMobileIcon} onClick={() => handlerClickMenu(false)}>
                            <span class="material-symbols-outlined">close</span>
                        </div>
                        :
                        <div className={style.menuMobileIcon} onClick={() => handlerClickMenu(true)}>
                            <span className="material-symbols-outlined">menu</span>
                        </div>
                }
            </div>
            }

            {menu && 
                       
            <div className={`${style.menuMobile}  ${openMenuMobile? style.active: ""}`}>  
                <div className={style.menuMobileContent}>
                  {
                    menu.map((element, index) => (                      
                        <NavItemMenu
                         key={index}
                         name={element.name}
                         link={element.link}
                         submenu= {element.submenu}
                         />
                        ))
                    }
                </div>
            </div>    
                                             
            }

            {menu && 
            <div className={style.menu}>   
                    {
                    menu.map((element, index) => (
                       
                        <NavItemMenu
                         key={index}
                         dropdown
                         name={element.name}
                         link={element.link}
                         submenu= {element.submenu}
                         />
                        ))
                    }
            </div>
            }

        </header>
    )
}
